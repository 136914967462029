import { Typography, Grid } from "@mui/material";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { THE_PROCESS_LIST } from "../../../data/StaticList";
import {
  APPSTORE_WHITE,
  CURVEDOWN,
  CURVEUP,
  PLAYSTORE_WHITE,
  PLAYSTORE_BLACK,
} from "../../../utils/ImageExport";
import { appLink } from "../../../utils/Constants";
import Image from "next/image";
import Link from "next/link";
import AppAnimation from "../../AppAnimation";

const TheProcess: React.FC = () => {
  return (
    <>
      <Grid
        container
        sx={{
          alignItems: "center",
          minHeight: { xs: "70vh", md: "100vh" },
          justifyContent: "center",
          padding: { xs: "50px 0", md: "3%" },
        }}
        style={{
          width: "100vw",
          backgroundColor: "#F4F7FF",
          position: "relative",
        }}
      >
        <Grid
          container
          sx={{
            width: "90%",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
            maxWidth: "1440px",
          }}
        >
          <Typography
           
            sx={{
              textAlign: "center",
              fontWeight: "700",
              fontSize: { xs: "24px", md: "40px" },
              marginBottom: { xs: "20px", md: "0" },
            }}
          >
            Start earning with 3 easy steps
          </Typography>

          <Grid
            container
            sx={{
              display: { xs: "none", md: "flex" },
              width: "100%",
              height: { xs: "400px", md: "500px", lg: "500px" },
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "nowrap",
            }}
          >
            {THE_PROCESS_LIST.map((item, i) => (
              <Grid
                key={i}
                container
                sx={{
                  height: "80%",
                  width: { xs: "400px", md: "30%", lg: "30%" },
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: "1px",
                  boxSizing: "border-box",
                  textAlign: "center",
                }}
              >
                <Grid
                  sx={{
                    height: { xs: "180px", md: "216px" },
                    width: { xs: "180px", md: "216px" },
                    borderRadius: "50%",
                    margin: "auto",
                    zIndex: "1",
                  }}
                >
                  <AppAnimation data={item.img} />
                </Grid>

                <Grid style={{ height: "80%" }}>
                  <Grid
                    sx={{
                      height: "40px",
                      width: "40px",
                      margin: { xs: "10px auto", md: "20px auto" },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      fontSize: "28px",
                      backgroundColor: "#27BF35",
                      color: "white",
                    }}
                  >
                    {item.id}
                  </Grid>
                  <Typography
                    
                    sx={{
                      padding: "0px 2px",
                      fontWeight: "bold",
                      fontSize: { xs: "20px", md: "24px" },
                    }}
                  >
                    {item.title}
                  </Typography>
                </Grid>
              </Grid>
            ))}

            {/* <Grid
              sx={{
                display: { xs: "none", md: "block" },
              }}
              style={{
                position: "absolute",
                height: "auto",
                width: "auto",
                left: "30%",
                top: "38%",
                zIndex: "0",
                rotate: "5deg",
              }}
            >
              <Image src={CURVEUP} alt="curveLine" loading="lazy" />
            </Grid>
            <Grid
              sx={{
                display: { xs: "none", md: "block" },
              }}
              style={{
                position: "absolute",
                height: "auto",
                width: "auto",
                right: "30%",
                top: "40%",
                zIndex: "0",
              }}
            >
              <Image src={CURVEDOWN} alt="curveDown" loading="lazy" />
            </Grid> */}
          </Grid>

          <Grid
            sx={{
              margin: "auto",
              width: "320px",
              paddingTop: "4px",
              display: { xs: "block", md: "none" },
            }}
          >
            <Carousel
              autoPlay
              interval={4000}
              infiniteLoop
              showStatus={false}
              showThumbs={false}
              swipeable={false}
            >
              {THE_PROCESS_LIST.map((item, i) => (
                <Grid
                  key={i}
                  sx={{
                    height: { xs: "350px", md: "400px" },
                    width: { xs: "300px", md: "30%", lg: "30%" },
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: "1px",
                    boxSizing: "border-box",
                    textAlign: "center",
                    margin: "20px auto",
                  }}
                >
                  <Grid
                    style={{
                      height: "180px",
                      width: "180px",
                      borderRadius: "50%",
                      margin: "auto",
                      zIndex: "1",
                    }}
                  >
                    <AppAnimation data={item.img} />
                  </Grid>

                  <Grid style={{ height: "60%" }}>
                    <Grid
                      style={{
                        height: "40px",
                        width: "40px",
                        margin: "20px auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        fontSize: "28px",
                        backgroundColor: "#27BF35",
                        color: "white",
                        wordWrap: "break-word",
                      }}
                    >
                      {item.id}
                    </Grid>
                    <Typography
                      
                      sx={{
                        padding: "0px 2px",
                        fontWeight: "bold",
                        wordWrap: "break-word",
                        fontSize: { xs: "22px", md: "24px" },
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Carousel>
          </Grid>

          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              sx={{
                height: { xs: "45px", md: "64px" },
                width: { xs: "140px", md: "216px" },
                marginRight: "25px",
                cursor: "pointer",
                display: { xs: "none", md: "block" },
              }}
            >
              <Link href={appLink} passHref>
                <Image
                  src={PLAYSTORE_BLACK}
                  alt="playStore"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  loading="lazy"
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TheProcess;
