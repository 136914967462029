import React from "react";
import Lottie, { Options } from "react-lottie";

function AppAnimation({ data }: { data: any }) {
  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: data,
  };
  return (
    <div>
      <Lottie options={defaultOptions} height={"100%"} width={"100%"} />
    </div>
  );
}

export default AppAnimation;
